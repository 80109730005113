import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import CreatePostIcon from "../../assets/images/feed/create/post.svg";
import ReviewIcon from "../../assets/images/feed/create/review.svg";
import CreateCollabIcon from "../../assets/images/feed/create/collaboration.svg";
import CreateSurveyIcon from "../../assets/images/feed/create/survey.svg";
import CreateQuizIcon from "../../assets/images/feed/create/quiz.svg";
import CreatePollIcon from "../../assets/images/feed/create/poll.svg";
import CreateCertificationIcon from "../../assets/images/feed/create/certification.svg";
import CreatePost from "./CreatePost";
import CreatePoll from "./CreatePoll";
import Avatar from "../user/Avatar";
import { getPremiumCompanies } from "../../actions/premium";
import {
  surveyLinks,
  quizLinks,
  collaborationInternalLinks,
  certificateLinks,
} from "../../utils/internalLinks";
import ReviewCreateButton from "../premium/ReviewCreateButton";
import { gaEvents } from "../../utils/events";
import { QUIZ_GA_EVENTS_TITLE } from "../../utils/quizHelper";
import MandatoryInformationModal from "../modals/MandatoryInformationModal";
import SurveyPricingModal from "../modals/survey/SurveyPricingModal";

const CreateBar = (props) => {
  const {
    token,
    history,
    user: {
      premiumCompanies,
      user: { profile, verified_at, companies, },
    },
    getPremiumCompanies,
    handleOpen,
  } = props;
  const [type, setType] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [mandatoryFieldModalVisible, setMandatoryFieldModalVisible] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [creationType, setCreationType] = useState("");
  const [showPricingModal, setShowPricingModal] = useState(false);

  const premiumCompaniesList = premiumCompanies && premiumCompanies.companies;
  const isUserorCompanyPremium = profile && profile.is_premium || (premiumCompaniesList && premiumCompaniesList.length);

  useEffect(() => {
    const isPremium = get(profile, "is_premium", 0);
    if (!premiumCompanies.hasFetched) {
      getPremiumCompanies(token).then((val) => {
        if (val && val.length) {
          setShowReview(true);
        } else {
          if (isPremium) {
            setShowReview(true);
          }
        }
      });
    } else {
      const { companies } = premiumCompanies;
      if (companies.length || isPremium) {
        setShowReview(true);
      }
    }
  }, [profile]);
  useEffect(() => {
    if (profile) {
      handleCheckFields();
    }
  }, [])

  const handleCheckFields = () => {
    setMandatoryFields(prevState => {
      let val = [...prevState];
      if (typeof verified_at === "object")
        val.push("verified_email") // val = [...val, "verified_email"]
      if (!profile.verified_phone)
        val.push("verified_phone") // val = [...val, "verified_phone"]
      return val;
    })
  }

  const handleUpdate = (type = null) => {
    if (type) {
      document.body.style.overflow = "hidden";
      setType(type);
      handleOpen(true);
      return;
    }
    document.body.style.overflow = "auto";
    setType(null);
    handleOpen(false);
  };

  const handleSurveyOrPollClose = () => {
    handleUpdate();
  };

  const closeMandatoryModal = () => {
    setMandatoryFieldModalVisible(false);
  }

  const handleLinkButtonClick = (type) => {
    switch (type) {
      case "collaboration":
        history.push(collaborationInternalLinks.create());
        break;
      case "poll":
        handleUpdate("poll");
        break;
      case "survey":
        if (isUserorCompanyPremium) {
          setShowPricingModal(true);
        } else {
          history.push(surveyLinks.create);
        }
        break;
      case "quiz":
        gaEvents.quiz.createBar(QUIZ_GA_EVENTS_TITLE);
        history.push(quizLinks.create);
        break;
      case "certification":
        history.push(certificateLinks.create);
        break;
      default:
        break;
    }
  };

  const handleCreateSurvey = () => {
    history.push(surveyLinks.create);
  }

  return (
    <div className="post-bar__container">
      {!!premiumCompanies.hasFetched && (
        <>
          <div className="post-bar">
            <div
              onClick={() => handleUpdate("post")}
              className={`post-bar__btn post-bar__btn--no-text ${type === "post" ? "post-bar__btn--active" : ""
                }`}
            >
              <img src={CreatePostIcon} alt="create post" />
            </div>

            {showReview && (
              <ReviewCreateButton classNameToAppend="post-bar__btn">
                <img
                  className="post-bar__btn__icon"
                  src={ReviewIcon}
                  alt="create review"
                />
                <span className="post-bar__btn__text">Review</span>
              </ReviewCreateButton>
            )}

            <button
              onClick={() => {
                if (mandatoryFields.length >= 1) {
                  setMandatoryFieldModalVisible(true);
                  setCreationType("collaboration")
                } else {
                  handleLinkButtonClick("collaboration")
                }
              }}
              className="post-bar__btn"
            >
              <img
                className="post-bar__btn__icon"
                src={CreateCollabIcon}
                alt="create collaboration"
              />
              <span className="post-bar__btn__text">Collaborate</span>
            </button>

            <button
              onClick={() => {
                if (mandatoryFields.length >= 1) {
                  setMandatoryFieldModalVisible(true);
                  setCreationType("poll")
                } else {
                  handleLinkButtonClick("poll")
                }
              }}
              className="post-bar__btn"
            >
              <img
                className="post-bar__btn__icon"
                src={CreatePollIcon}
                alt="create poll"
              />
              <span className="post-bar__btn__text">Poll</span>
            </button>

            <button
              onClick={() => {
                if (mandatoryFields.length >= 1) {
                  setMandatoryFieldModalVisible(true);
                  setCreationType("survey")
                } else if (isUserorCompanyPremium) {
                  setShowPricingModal(true);
                } else {
                  handleLinkButtonClick("survey")
                }

              }}
              className="post-bar__btn"
            >
              <img
                className="post-bar__btn__icon"
                src={CreateSurveyIcon}
                alt="create survey"
              />
              <span className="post-bar__btn__text">Survey</span>
            </button>
            <button
              onClick={() => {
                if (mandatoryFields.length >= 1) {
                  setMandatoryFieldModalVisible(true);
                  setCreationType("quiz")
                } else {
                  handleLinkButtonClick("quiz")
                }
              }}
              className="post-bar__btn"
            >
              <img
                className="post-bar__btn__icon"
                src={CreateQuizIcon}
                alt="create quiz"
              />
              <span className="post-bar__btn__text">Quiz</span>
            </button>
            {showReview ? <button
              onClick={() => {
                if (mandatoryFields.length >= 1) {
                  setMandatoryFieldModalVisible(true);
                  setCreationType("certification")
                } else {
                  handleLinkButtonClick("certification")
                }
              }}
              className="post-bar__btn certification"
            >
              <img
                className="post-bar__btn__icon"
                src={CreateCertificationIcon}
                alt="create certification"
              />
              <span className="post-bar__btn__text">Certification</span>
            </button> : null}
          </div>

          {type && (
            <div className="post-bar__create-modal">
              {type === "post" && (
                <CreatePost handleClose={() => handleUpdate()} />
              )}
              {type === "poll" && (
                <CreatePoll handleClose={handleSurveyOrPollClose} />
              )}
            </div>
          )}

          {!type && profile ? (
            <div
              onClick={() => handleUpdate("post")}
              className="post-bar__post post-bar__post--blank"
            >
              <div className="post-bar__post__arrow"></div>
              <div className="user-create-block">
                <Avatar
                  alt={get(profile, "name", "")}
                  className="user-create-block__avatar"
                  src={get(profile, "imageUrl", "")}
                />
              </div>
              <p className="post-bar__post--blank__helper">Post something...</p>
            </div>
          ) : (
            <div className="post-bar__post post-bar__post--blank">
              {!type && <div className="post-bar__post__arrow"></div>}
              <div className="user-create-block">
                <div className="user-create-block__avatar"></div>
              </div>
              <p className="post-bar__post--blank__helper">Post something...</p>
            </div>
          )}
        </>
      )}
      {mandatoryFieldModalVisible &&
        <MandatoryInformationModal
          useFor="creation"
          mandatoryFields={mandatoryFields}
          closeModal={closeMandatoryModal}
          proceedAction={() => {
            setMandatoryFieldModalVisible(false);
            handleLinkButtonClick(creationType)
          }}
          profile={profile}
        />}
      {
        showPricingModal && <SurveyPricingModal
          handleToggle={() => setShowPricingModal(false)}
          token={token}
          handleClick={handleCreateSurvey}
          profile={profile}
          history={history}
          premiumCompanies={premiumCompaniesList}
        />
      }
      {type && <div className="create-bg"></div>}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

CreateBar.propTypes = {
  user: PropTypes.object,
  token: PropTypes.string,
  history: PropTypes.object,
  handleOpen: PropTypes.func,
  getPremiumCompanies: PropTypes.func,
};

export default withRouter(
  connect(mapStateToProps, { getPremiumCompanies })(CreateBar)
);
