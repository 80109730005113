import React, { useEffect, useState } from "react";
import PaidProgramIcon from "../assets/images/profile/view/paidprogram.svg";
import MyCompanyIcon from "../assets/images/profile/popup/mycompanies.svg";
import Reward from "../assets/images/profile/popup/reward.svg";
import MyPassbook from "../assets/images/profile/popup/passbook.svg";
import MyParticipation from "../assets/images/profile/popup/participation.svg";
import Invite from "../assets/images/profile/popup/invite.svg";
import Setting from "../assets/images/profile/popup/settings.svg";
import Password from "../assets/images/profile/popup/password.svg";
import TastingIcon from "../assets/images/profile/popup/tasting.svg";
import CollaborationIcon from "../assets/images/profile/popup/collaboration.svg";
import SurveyIcon from "../assets/images/profile/popup/survey.svg";
import QuizIcon from "../assets/images/profile/popup/quiz.svg";
import CertificateIcon from "../assets/images/profile/popup/certificate.svg";
import InvitationIcon from "../assets/images/profile/popup/invitations.svg";
import WorkshopIcon from "../assets/images/profile/popup/workshop.svg";
import Login from "../assets/images/profile/popup/logout.svg";
import BlackArrow from "../assets/images/profile/popup/blackarrow.svg";
import ChevronRight from "../assets/images/business/chevron-right.svg";
import { decodeString } from "../utils/helper";
import get from "lodash/get";
import { companiesLink, miscLinks, profileLinks } from "../utils/internalLinks";
import Avatar from "./user/Avatar";
import { Link } from "react-router-dom";
import UserName from "./profile/UserName";
import RedTriangle from "../assets/images/profile/popup/redarrow.svg";
import ReactTooltip from "react-tooltip";

const activitiesList = [
  {
    label: "Collaborations",
    icon: CollaborationIcon,
    value: profileLinks.myCollaborations
  },
  {
    label: "Tastings",
    icon: TastingIcon,
    value: profileLinks.myTastings
  },
  {
    label: "Workshops",
    icon: WorkshopIcon,
    value: profileLinks.myWorkshop
  },
  {
    label: "Surveys",
    icon: SurveyIcon,
    value: profileLinks.mySurvey
  },
  {
    label: "Quizzes",
    icon: QuizIcon,
    value: profileLinks.myQuiz
  },
  {
    label: "Certifications",
    icon: CertificateIcon,
    value: profileLinks.myCertification
  },
  {
    label: "Invitations",
    icon: InvitationIcon,
    value: profileLinks.myInvitation
  }
]

const HeaderComponent = (props) => {
  const { handleToggleList, companies, toggleModal, view } = props;
  const companiesLength = get(props, "companies", []).length
  const title = view === "companiesList" ? `COMPANY PAGES (${companiesLength})` : "MY PARTICIPATIONS"
  return (
    <div className={`profile-menu-popup__list`}>
      <div className="popup-head">
        <span className="back-button cursor-pointer" onClick={(e) => handleToggleList(e, null)}><img src={BlackArrow} />Back</span>
        {view === "companiesList" && <div
          onClick={() => toggleModal("company")}
        >
          <span className="profile-menu-popup__list__title profile-menu-popup__list__title--primary">
            +Create new page
          </span>
        </div>}
      </div>
      <p className="popup__title">{title}</p>
    </div>
  )
}
const CompaniesList = (props) => {
  const { companies } = props;
  const companiesLength = get(props, "companies", []).length;
  return (
    <>
      {!!companiesLength ?
        companies.map((company, companyIndex) => {
          if (!company) return null;
          const companyName = decodeString(get(company, "name", ""));
          const companyLink = companiesLink.view(company.id);
          const companyVerified = !!company.verified;
          return (
            <li key={`companies-${companyIndex}-key`}>
              <Link
                className="profile-menu-popup__list__title company"
                to={companyLink}
              >
                <Avatar
                  alt={`${companyName} profile link`}
                  src={company.logo}
                  className="profile-menu-popup__list__img"
                  isCompany
                />
                <p className="username">
                  <UserName
                    isVerified={companyVerified}
                    addClassToText
                    name={companyName}
                  />
                  <span className="subtitle">company</span>
                </p>
              </Link>
            </li>
          );
        }) : <li className="profile-menu-popup__list__title nocompany">No Companies</li>}
    </>
  )
}

const MyActivites = () => {
  return (
    <>
      {
        activitiesList.map((activity) => {
          return <li><Link
            to={activity.value}
            className="profile-menu-popup__list__title"
          >
            <p className="with-image">
              <img src={activity.icon} />{activity.label}
            </p>
          </Link></li>
        })
      }
    </>
  )
}

const ProfilePopUpListItem = (props) => {
  const { handleToggleList,
    toggleModal,
    companies = [],
    handleDonationModal,
    handleOpenPassbook,
    handleLogout,
    profile, handleOpenPaidTasterProgram, view } = props;
  const formattedEarning = decodeString(
    get(profile, "payment.formatted_earning", "")
  );
  const isPaidTester = get(profile, "is_paid_taster", 0);
  const earning = get(profile, "payment.earning", 0);
  const isDonationPresent = profile.is_donation !== null;
  return (
    <ul className={`profile-menu-popup__list main ${view !== null ? 'show' : ''}`}>
      {
        !view ? <>
          {!isPaidTester && (
            <li className="profile-menu-popup__paidprogram">
              <div
                className="profile-menu-popup__paidprogram__header cursor-pointer"
                onClick={handleOpenPaidTasterProgram}
              >
                <img
                  className="profile-menu-popup__paidprogram__header__icon"
                  src={PaidProgramIcon}
                  alt="PaidProgramIcon"
                />
                <div style={{ marginLeft: "8px" }}>
                  <span className="profile-menu-popup__paidprogram__header__text">
                    Paid Taster Program
                  </span>
                  <p class="profile-menu-popup__paidprogram__header__subtitle">GET PAID FOR REVIEWS</p>
                </div>
                <img
                  className="profile-menu-popup__paidprogram__header__chevron"
                  src={ChevronRight}
                  alt="chevron right"
                />
              </div>
            </li>
          )}
          <li className="profile-menu-popup__list__title" onClick={(e) => handleToggleList(e, "companiesList")}>
            <p className="with-image"><img src={MyCompanyIcon} />My companies</p>
            <div className="company-icon">{companies.length}<img src={RedTriangle} alt="close profile popup" /></div>
          </li>
          {!!isPaidTester &&
            <li
              className="cursor-pointer profile-menu-popup__list__title profile-menu-popup__reward_preference-div"
              onClick={() => handleDonationModal("profile")}
            >
              <p className="with-image">
                <img src={Reward} />Reward allocation
              </p>
              {!!isDonationPresent && <div className={`profile-menu-popup__reward_preference-flex profile-reward ${!!profile.is_donation ? "donate" : ""}`}>
                {!!isDonationPresent && <p
                  style={{ backgroundColor: !!profile.is_donation ? "#F47816" : "#4990E2" }}
                  className="profile-menu-popup__reward_preference-text-circle"></p>}
                {!!isDonationPresent && <p className="profile-menu-popup__reward_preference-text"
                >
                  {!!profile.is_donation ? <p className="profile-menu-popup__reward_preference-text-donate">Donate</p> : <p>Self</p>}
                </p>}
              </div>}
            </li>
          }
          <li className="profile-menu-popup__list__title passbook" onClick={() => handleOpenPassbook(earning)}>
            <p className="with-image">
              <img src={MyPassbook} />My passbook
            </p>
            <div
              className="profile-menu-popup__passbook"
              {...(earning > 0
                ? {}
                : { "data-tip": "No transaction history available" })}
            >
              <span className="profile-menu-popup__passbook__earning__amount">
                {formattedEarning}
              </span>
            </div>
          </li>
          {earning === 0 && (
            <ReactTooltip
              clickable
              event="click"
              place="bottom"
              arrowColor="transparent"
            />
          )}

          <li className="profile-menu-popup__list__title" onClick={(e) => handleToggleList(e, "myActivity")}>
            <p className="with-image">
              <img src={MyParticipation} />My participations
            </p></li>

          <li
            onClick={() => toggleModal("invite")}
            className="profile-menu-popup__list__title"
          >
            <p className="with-image">
              <img src={Invite} />Invite
            </p>
          </li>
          <li>
            <Link
              className="profile-menu-popup__list__title"
              to={miscLinks.settings}
            >
              <p className="with-image"><img src={Setting} />Setting</p>
            </Link>
          </li>
          {profile.addPassword ? (
            <li className="profile-menu-popup__list__title" onClick={() => toggleModal("create_password")}>
              <p
                className="with-image"
              >
                <img src={Password} />Create Password
              </p>
            </li>
          ) : (
            <li className="profile-menu-popup__list__title" onClick={() => toggleModal("change_password")}>
              <p
                className="with-image"
              >
                <img src={Password} />Change Password
              </p>
            </li>
          )}
          <li className="profile-menu-popup__list__title" onClick={handleLogout}>
            <p className="with-image"
            >
              <img src={Login} />Logout
            </p>
          </li>
        </> : null
      }
    </ul>
  )
}

const ProfilePopUpSubListItem = (props) => {
  const { view } = props;
  return (
    <ul className={`profile-menu-popup__list activity ${view !== null ? "show" : ""}`} onClick={(e) => {
      if (!e) return;
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }}>
      {
        view ? <>
          <HeaderComponent {...props} />
          {view === "myActivity" ? <MyActivites {...props} /> : <CompaniesList {...props} />}
        </> : null
      }
    </ul>
  )
}

function ProfilePopUpList(props) {
  const { companies } = props;
  const [view, setView] = useState(null);
  const handleToggleList = (e, type) => {
    if (!e) return;
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    setView(type);
  }
  return (
    <div className="slider-div">
      <ProfilePopUpSubListItem
        handleToggleList={handleToggleList}
        view={view}
        {...props}
      />
      <ProfilePopUpListItem
        handleToggleList={handleToggleList}
        view={view}
        {...props}
      />
    </div>
  )
}

export default ProfilePopUpList;
