import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import Loader from "../../components/misc/Loader";
import CloseIcon from "../../assets/images/common/modals/close.svg";
import CreatePostIcon from "../../assets/images/feed/create/post.svg";
import ReviewIcon from "../../assets/images/feed/create/review.svg";
import ConsistencyIcon from "../../assets/images/feed/create/consistency.svg";
import CreateCollabIcon from "../../assets/images/feed/create/collaboration.svg";
import CreatePollIcon from "../../assets/images/feed/create/poll.svg";
import CreateSurveyIcon from "../../assets/images/feed/create/survey.svg";
import CreateQuizIcon from "../../assets/images/feed/create/quiz.svg";
import CreateCertificationIcon from "../../assets/images/feed/create/certification.svg";
import { getPremiumCompanies } from "../../actions/premium";
import SurveyPricingModal from "../modals/survey/SurveyPricingModal";
import { surveyLinks } from "../../utils/internalLinks";

const CreateBarMobile = (props) => {
  const {
    token,
    user: {
      premiumCompanies,
      user: { profile, companies },
    },
    getPremiumCompanies,
    toggleCreateModalMobile,
    handleLinkButtonClick,
    history
  } = props;

  const [showReview, setShowReview] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);

  const premiumCompaniesList = premiumCompanies && premiumCompanies.companies;
  const isUserorCompanyPremium = profile && profile.is_premium || (premiumCompaniesList && premiumCompaniesList.length);

  const handleCreateSurvey = () => {
    history.push(surveyLinks.create);
  }

  const handleToggle = () => {
    setShowPricingModal(!showPricingModal);
  }


  useEffect(() => {
    const isPremium = get(profile, "is_premium", 0);
    if (!premiumCompanies.hasFetched) {
      getPremiumCompanies(token).then((val) => {
        if (val && val.length) {
          setShowReview(true);
        } else {
          if (isPremium) {
            setShowReview(true);
          }
        }
      });
    } else {
      const { companies } = premiumCompanies;
      if (companies.length || isPremium) {
        setShowReview(true);
      }
    }
  }, [profile]);

  return (
    <>
      <div className="createbar__modal top-bar__content__right">
        <div className="createbar__modal top-bar__content__user-links__container">
          <div className="full-screen-modal">
            <div className="createbar__modal__header">
              <span className="createbar__modal__header__text">
                Post Something
              </span>
              <button
                onClick={toggleCreateModalMobile}
                className="createbar__modal__header__close"
              >
                <img src={CloseIcon} alt="close modal" />
              </button>
            </div>

            {!premiumCompanies.hasFetched ? (
              <div className="createbar__modal__content">
                <Loader isCentered={true} withMargin={true} />
              </div>
            ) : (
              <div className="createbar__modal__content">
                <div
                  className="createbar__modal__content__item"
                  onClick={() => handleLinkButtonClick("post")}
                >
                  <img
                    src={CreatePostIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Post
                  </span>
                </div>

                {showReview && (
                  <div
                    className="createbar__modal__content__item"
                    onClick={() => handleLinkButtonClick("review")}
                  >
                    <img
                      src={ReviewIcon}
                      alt="close modal"
                      className="createbar__modal__content__item__icon"
                    />
                    <span className="createbar__modal__content__item__text">
                      Product Review
                    </span>
                  </div>
                )}
                {showReview && (
                  <div
                    className="createbar__modal__content__item"
                    onClick={() => handleLinkButtonClick("consistency")}
                  >
                    <img
                      src={ConsistencyIcon}
                      alt="close modal"
                      className="createbar__modal__content__item__icon"
                    />
                    <span className="createbar__modal__content__item__text">
                      Track Product Consistency
                    </span>
                  </div>
                )}

                <div
                  className="createbar__modal__content__item"
                  onClick={() => handleLinkButtonClick("collaboration")}
                >
                  <img
                    src={CreateCollabIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Collaboration
                  </span>
                </div>

                <div
                  className="createbar__modal__content__item"
                  onClick={() => handleLinkButtonClick("poll")}
                >
                  <img
                    src={CreatePollIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Poll
                  </span>
                </div>
                <div
                  className="createbar__modal__content__item"
                  onClick={() => {
                    if (isUserorCompanyPremium) {
                      handleToggle();
                    } else {
                      handleLinkButtonClick("survey")

                    }
                  }
                  }
                >
                  <img
                    src={CreateSurveyIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Survey
                  </span>
                </div>
                <div
                  className="createbar__modal__content__item"
                  onClick={() => handleLinkButtonClick("quiz")}
                >
                  <img
                    src={CreateQuizIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Quiz
                  </span>
                </div>
                {showReview ? <div
                  className="createbar__modal__content__item"
                  onClick={() => handleLinkButtonClick("certification")}
                >
                  <img
                    src={CreateCertificationIcon}
                    alt="close modal"
                    className="createbar__modal__content__item__icon"
                  />
                  <span className="createbar__modal__content__item__text">
                    Certification
                  </span>
                </div> : null}
              </div>
            )}
          </div>
        </div>
      </div>
      {
        showPricingModal && <SurveyPricingModal
          handleToggle={handleToggle}
          token={token}
          handleClick={handleCreateSurvey}
          profile={profile}
          history={history}
          premiumCompanies={premiumCompaniesList}
        />
      }
    </>
  );
};

function mapStateToProps(state) {
  return {
    token: state.login.token,
    user: state.user,
  };
}

CreateBarMobile.propTypes = {
  token: PropTypes.string,
  toggleCreateModalMobile: PropTypes.func,
  handleOpen: PropTypes.func,
  handleLinkButtonClick: PropTypes.func,
  user: PropTypes.object,
  getPremiumCompanies: PropTypes.func,
};
export default withRouter(
  connect(mapStateToProps, { getPremiumCompanies })(CreateBarMobile)
);
